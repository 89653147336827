import { FolderSmallIcon, ScanClusterIcon, ScanIcon } from "@faro-lotv/flat-ui";
import {
  CaptureTreeEntity,
  CaptureTreeEntityType,
  isCaptureTreeEntityRevision,
  isRevisionScanEntity,
} from "@faro-lotv/service-wires";
import { SxProps } from "@mui/system";

interface EntityIconProps {
  /** entity to show the icon for */
  entity: CaptureTreeEntity;

  /** styles to apply to the icon */
  sx: SxProps;
}

/** @returns icon for a capture tree entity */
export function EntityIcon({ entity, sx }: EntityIconProps): JSX.Element {
  if (isCaptureTreeEntityRevision(entity) && isRevisionScanEntity(entity)) {
    return <ScanIcon sx={sx} />;
  }

  switch (entity.type) {
    case CaptureTreeEntityType.cluster:
      return <ScanClusterIcon sx={sx} />;
  }

  // Show a folder as a fallback
  return <FolderSmallIcon sx={sx} />;
}
