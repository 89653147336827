/**
 * @file Metric types and validators for multi-cloud registration.
 * See https://faro01.atlassian.net/wiki/spaces/NewRegistr/pages/3602284752/Registration+Report+JSON+and+Schema#Registration-Report-Schema-(JSON).
 */

import { RootState } from "@/store/store";
import {
  GUID,
  PropOptional,
  validateArrayOf,
  validateNotNullishObject,
  validateOfType,
  validatePrimitive,
  walkWithQueue,
} from "@faro-lotv/foundation";
import {
  IElementTimeSeriesDataSession,
  IPose,
  isIElementTimeseriesDataSession,
  validatePose,
} from "@faro-lotv/ielement-types";
import { selectAncestor, selectIElement } from "@faro-lotv/project-source";
import { RegistrationEdgeRevision } from "@faro-lotv/service-wires";
import { Selector } from "@reduxjs/toolkit";
import { RegistrationThresholdSet } from "../common/registration-report/registration-thresholds";
import { QualityStatus, getQualityStatus } from "./metrics";

export type MultiRegistrationReport = {
  /** The revision of the json file representing the registration. */
  jsonRevision: number;

  /** A unique id for the project. */
  projectId: GUID;

  /** The method used for registration. */
  method: string;

  /** The used voxel size, in meters. */
  subsampling: number;

  /** A list of generic metrics from the scan. */
  scanMetrics: CombinedMetrics;

  /** The new local poses calculated during the registration. */
  updatedLocalIElementPoses?: UpdatedLocalPose[] | null;

  /** The scans for which the report was generated. */
  scans: { children: Array<Cluster | Scan> };
};

/**
 * @param value The value to check for its type.
 * @returns `true`, if `value` is a valid `MultiRegistrationReport`, else `false`.
 */
export function validateMultiRegistrationReport(
  value: unknown,
): value is MultiRegistrationReport {
  if (
    !validateNotNullishObject<MultiRegistrationReport>(
      value,
      "MultiRegistrationReport",
    )
  ) {
    return false;
  }

  return (
    // jsonRevision
    validatePrimitive(value, "jsonRevision", "number") &&
    // projectId
    validatePrimitive(value, "projectId", "string") &&
    // method
    validatePrimitive(value, "method", "string") &&
    // subsampling
    validatePrimitive(value, "subsampling", "number") &&
    // scanMetrics
    validateCombinedMetrics(value.scanMetrics) &&
    // updatedLocalIElementPoses
    (value.updatedLocalIElementPoses === undefined ||
      validateArrayOf({
        object: value,
        prop: "updatedLocalIElementPoses",
        elementGuard: validateUpdatedLocalPose,
        optionality: PropOptional,
      })) &&
    // scans
    !!value.scans &&
    validateArrayOf({
      object: value.scans,
      prop: "children",
      elementGuard: (elem) => validateScan(elem) || validateCluster(elem),
    })
  );
}

export type UpdatedLocalPose = {
  /** The ID of the IElement to update the pose of. */
  id: GUID;

  /**
   * The new _local_ pose of the IElement.
   *
   * Given in Y-up left-handed reference system, the same as the Project API.
   */
  pose: IPose;
};

/**
 * @param value The value to check for its type.
 * @returns `true`, if `value` is a valid `UpdatedLocalPose`, else `false`.
 */
export function validateUpdatedLocalPose(
  value: unknown,
): value is UpdatedLocalPose {
  if (!validateNotNullishObject<UpdatedLocalPose>(value, "UpdatedLocalPose")) {
    return false;
  }

  return (
    // id
    validatePrimitive(value, "id", "string") &&
    // pose
    validateOfType(value, "pose", validatePose)
  );
}

export type CombinedMetrics = {
  /** Maximum Point error, in meters. */
  maxPointError: number;

  /** Average Point error, in meters. */
  averagePointError: number;

  /** Minimum overlap, in meters. */
  minOverlap: number;
};

/**
 * @param value The value to check for its type.
 * @returns `true`, if `value` is valid `CombinedMetrics`, else `false`.
 */
export function validateCombinedMetrics(
  value: unknown,
): value is CombinedMetrics {
  if (!validateNotNullishObject<CombinedMetrics>(value, "CombinedMetrics")) {
    return false;
  }

  return (
    // maxPointError
    validatePrimitive(value, "maxPointError", "number") &&
    // averagePointError
    validatePrimitive(value, "averagePointError", "number") &&
    // minOverlap
    validatePrimitive(value, "minOverlap", "number")
  );
}

export type Scan = {
  /** Name of the scan. */
  name: string;

  /** A unique id for the scan. */
  uuid: GUID;

  /** Deviation of the inclinometer in degree, after the optimization. */
  inclinometerDeviation?: number | null;

  /** A collection of registrations for this scan. */
  registrations: Registration[];
};

/**
 * @param value The value to check for its type.
 * @returns `true`, if `value` is a valid `Scan`, else `false`.
 */
export function validateScan(value: unknown): value is Scan {
  if (!validateNotNullishObject<Scan>(value, "Scan")) {
    return false;
  }

  return (
    // name
    validatePrimitive(value, "name", "string") &&
    // uuid
    validatePrimitive(value, "uuid", "string") &&
    // inclinometerDeviation
    (value.inclinometerDeviation === undefined ||
      validatePrimitive(
        value,
        "inclinometerDeviation",
        "number",
        PropOptional,
      )) &&
    // registrations
    validateArrayOf({
      object: value,
      prop: "registrations",
      elementGuard: validateRegistration,
    })
  );
}

export type Cluster = {
  clusterMetrics?: CombinedMetrics;

  /** A unique id for the cluster. */
  uuid: GUID;

  /** Name of the cluster. */
  name: string;

  /** The items included in the cluster. */
  children: Array<Cluster | Scan>;
};

/**
 * @param value The value to check for its type.
 * @returns `true`, if `value` is a valid `Cluster`, else `false`.
 */
export function validateCluster(value: unknown): value is Cluster {
  if (!validateNotNullishObject<Cluster>(value, "Cluster")) {
    return false;
  }

  return (
    // name
    validatePrimitive(value, "name", "string") &&
    // uuid
    validatePrimitive(value, "uuid", "string") &&
    // clusterMetrics
    (value.clusterMetrics === undefined ||
      validateCombinedMetrics(value.clusterMetrics)) &&
    // children
    validateArrayOf({
      object: value,
      prop: "children",
      elementGuard: (elem) => validateScan(elem) || validateCluster(elem),
    })
  );
}

export type Registration = {
  /** The ID of the object containing the registration data. */
  registrationObjectId: GUID;

  /** A unique id for the target scan. */
  targetScanId: string;

  /** The metrics indicating the quality of the registration. */
  metrics: RegistrationMetrics;
};

/**
 * The data from the RegistrationEdgeRevision, with its needed RegistrationMetrics.
 */
export type LocalRevisionEdge = RegistrationEdgeRevision<unknown> & {
  metrics: RegistrationMetrics;
};

/**
 * @param value The value to check for its type.
 * @returns `true`, if `value` is a valid `Registration`, else `false`.
 */
export function validateRegistration(value: unknown): value is Registration {
  if (!validateNotNullishObject<Registration>(value, "Registration")) {
    return false;
  }

  return (
    // registrationObjectId
    validatePrimitive(value, "registrationObjectId", "string") &&
    // targetScanId
    validatePrimitive(value, "targetScanId", "string") &&
    // metrics
    validateRegistrationMetrics(value.metrics)
  );
}

export type RegistrationMetrics = {
  /** Distribution of point distances over all points in the registered point clouds. */
  rlyHistogram: RlyHistogram;

  /** Clip Chamfer metric. */
  clipChamferDistance: number;

  /** Overlap ratio between the point clouds. */
  overlap: number;

  /** FScore of the registration. */
  fscore: number;

  /** Number of cascades the algorithm did do increase the precision */
  numberOfCascades?: number;
};

/**
 * @param value The value to check for its type.
 * @returns `true`, if `value` are valid `RegistrationMetrics`, else `false`.
 */
export function validateRegistrationMetrics(
  value: unknown,
): value is RegistrationMetrics {
  if (
    !validateNotNullishObject<RegistrationMetrics>(value, "RegistrationMetrics")
  ) {
    return false;
  }

  return (
    // rlyHistogram
    validateRlyHistogram(value.rlyHistogram) &&
    // clipChamferDistance
    validatePrimitive(value, "clipChamferDistance", "number") &&
    // overlap
    validatePrimitive(value, "overlap", "number") &&
    // fscore
    validatePrimitive(value, "fscore", "number")
  );
}

export type RlyHistogram = {
  /**
   * The histogram bins as array.
   *
   * Each entry determines the number of points in each bin.
   */
  bins: number[];

  /** The histogram resolution aka width of each bin. */
  resolution: number;

  /** The bounding box of the histogram, in meters. Empty if histogram is empty. */
  limits: [number, number] | [];

  /** The median value of the histogram data. */
  median: number;

  /** Whether we use Point2Plane or Point2Point distance. */
  distanceType: DistanceType;
};

/**
 * @param value The value to check for its type.
 * @returns `true`, if `value` is a valid `RlyHistogram`, else `false`.
 */
export function validateRlyHistogram(value: unknown): value is RlyHistogram {
  if (!validateNotNullishObject<RlyHistogram>(value, "RlyHistogram")) {
    return false;
  }

  return (
    // bins
    validateArrayOf({
      object: value,
      prop: "bins",
      elementGuard: (x) => typeof x === "number",
    }) &&
    // resolution
    validatePrimitive(value, "resolution", "number") &&
    // limits
    (validateArrayOf({
      object: value,
      prop: "limits",
      size: 2,
      elementGuard: (x) => typeof x === "number",
    }) ||
      validateArrayOf({
        object: value,
        prop: "limits",
        size: 0,
        elementGuard: (x) => typeof x === "number",
      })) &&
    // median
    validatePrimitive(value, "median", "number") &&
    // distanceType
    validateDistanceType(value.distanceType)
  );
}

export enum DistanceType {
  /** The distances are between the points and a plane. */
  point2Plane = "Point2Plane",
  /** The distances are between two points. */
  point2Point = "Point2Point",
}

/**
 * @param value The value to check for its type.
 * @returns `true`, if `value` is a valid `DistanceType`, else `false`.
 */
export function validateDistanceType(value: unknown): value is DistanceType {
  return (
    typeof value === "string" &&
    Object.values<string>(DistanceType).includes(value)
  );
}

/**
 * @param metrics The metrics to determine the quality of.
 * @param thresholdSet The set of thresholds to use to determine the quality of the metric values
 * @returns A unified quality status for all metrics.
 */
function determineCombinedMetricsQuality(
  metrics: CombinedMetrics,
  thresholdSet: RegistrationThresholdSet,
): QualityStatus {
  const maxErrorQuality = getQualityStatus(
    metrics.maxPointError,
    thresholdSet.pointDistance,
  );
  const avgErrorQuality = getQualityStatus(
    metrics.averagePointError,
    thresholdSet.pointDistance,
  );
  const overlapQuality = getQualityStatus(
    metrics.minOverlap,
    thresholdSet.overlap,
  );
  const qualities = [maxErrorQuality, avgErrorQuality, overlapQuality];

  // Take the worst quality of all metrics
  if (qualities.includes(QualityStatus.POOR)) {
    return QualityStatus.POOR;
  } else if (qualities.includes(QualityStatus.MEDIUM)) {
    return QualityStatus.MEDIUM;
  } else if (qualities.includes(QualityStatus.GOOD)) {
    return QualityStatus.GOOD;
  }

  return QualityStatus.UNKNOWN;
}

/**
 * @param report The report to determine the quality of.
 * @param thresholdSet The thresholds to use to determine the quality of the metric values
 * @returns The combined quality of the multi cloud registration.
 */
export function determineReportQuality(
  report: MultiRegistrationReport,
  thresholdSet: RegistrationThresholdSet,
): QualityStatus {
  return determineCombinedMetricsQuality(report.scanMetrics, thresholdSet);
}

/**
 * @param report The report to determine the data session of.
 * @returns The data session that was registered, with the results of the report.
 */
export function selectReportTimeseriesDataSession(
  report: MultiRegistrationReport,
): Selector<RootState, IElementTimeSeriesDataSession | undefined> {
  // Could be more efficient in the future https://faro01.atlassian.net/browse/NRT-687
  const scans = reportScans(report);

  return (state: RootState) => {
    if (!scans.length) return undefined;

    const scanElement = selectIElement(scans[0].uuid)(state);
    return selectAncestor(scanElement, isIElementTimeseriesDataSession)(state);
  };
}

/**
 * @param report The report to retrieve all the scans of.
 * @returns All scans in the registration report.
 */
export function reportScans(report: MultiRegistrationReport): Scan[] {
  const scans: Scan[] = [];

  walkWithQueue([...report.scans.children], (child, queue) => {
    if (validateScan(child)) {
      scans.push(child);
    } else {
      queue(...child.children);
    }
  });

  return scans;
}
